import { getLocal, removeLocal } from "@/src/utils/local";
import { useLoginStore } from '@/src/store/modules/login'
export default defineNuxtRouteMiddleware((to,from) =>{
    const authpage = ['designs','personalCenter','myDesign','createdesigns']
    //console.log(to.name,'to.name');
    if(authpage.includes(to.name) && !islogin()){
        if (process.client) {
          if(from.name == undefined){
            return navigateTo('/')
          }else{
            const loginStore = useLoginStore()
            loginStore.openloginstate()
            return abortNavigation()
          }
       }
    }

})

function islogin(){
    return getLocal('token');
}