import $axios from "axios";
import axios from 'axios';
axios.defaults.baseURL = 'http://kf.weipark.club'; // 设置你的 API 基本 URL
import {
  posterListUri,
  postFontMaterial,
  postGroupUri,
  postTemplateListUri,
  postWechatLoginUri,
  postWechatUri,
  postFontGroupMaterial,
  postBgMaterial,
  postBgGroupMaterial,
  postImageGroup,
  postImageGroupList,
  postMaterialList,
  postGroupMaterialList,
  postTemplateTemurlinfoUrl,
  postfontlist,
  postlikestate,
  postUploadGroupList,
  postUploadGroupAdd,
  postUploadGroupDel,
  postUploadGroupModify,
  getUploadAuth,
  postUploadImgList,
  postUploadImgAdd,
  postUploadImgModify,
  postUploadImgDel,
  postCollectionList,
  postMyTemplate,
  postTemplateMyDesignList,
  postCanvasSizeList,
  postRecommendToYouList,
  postCreateDesignList,
  postMytTemplateGroup,
  postMyTemplateTitle,
  postDeleteMyTemplate,
  postMaterialImgList,
  postMaterialSvgList,
  postMaterialFontList,
  postMaterialGroup,
  postSearchMaterialImg,
  postUserInfo,
  postUserOrderList,
  postInvoiceList,
  postMyInvoice,
  postConfigLimit,
  postHotSearch,
  postauth,
  postdown,
  postdownnum,
  getcolorurl,
  postChangeBindingUserInfo,
  postInvoiceRecords,
  postMaterialImgInfo,
  postPriceList,
  postSeoList,
  postPassageway,
  postorderStatus,
  postCheckGroupAuth,
  postMemberPayInfo,
  postMyTemplateTemurlinfoUrl,
  postHelpList,
  postSearchHelpList,
  postHelpInfo,
  posthasfontUrl,
  posttemplateGroupUrl, postwaptemplateGroupUrl,postwaphelpGroupUrl,postwaphelplistUrl,postwapwechatUrl,postwapwechatLoginUrl
} from "./url";

export async function getPosterList(params: any) {
  try {
    const  response = await $axios.post(posterListUri,{page:params.page,'condition':params.condition,group_id:params.gid,industry_id:params.id,color_id:params.cid,style_id:params.sid,'sort':params.sort,'nid':params.nid,'uid':params.uid,'bid':params.bid});
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getPosterGroup(params:any){
  try {
    const  response = await $axios.post(postGroupUri,{gid:params.gid});
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
}
export async function getTemplateDetails(params: any) {
  try {
    const  response = await $axios.post(postTemplateListUri,{id:params.id});
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
export async function getWechatImage(params:any){
  try {
    const response = await $axios.post(postWechatUri);
    return response.data;
  } catch (error){
    console.log(error)
  }
}

export async function getWechatLogin(params:any){
  try {
    const response = await $axios.post(postWechatLoginUri,{uniqid:params.uniqid});
    return response.data;
  }catch (error){
    console.log(error)
  }

}
// 获取编辑器文字素材列表 -> 文字 -> 列表
export async function getFontMaterial(params:any){
  try {
    const response = await $axios.post(postFontMaterial,{uid:params.uid,page:params.page});
    return response.data.data;
  }catch (error){
    console.log(error)
  }

}

// 获取编辑器文字素材列表 -> 文字 -> 列表->全部
export async function getFontGroupMaterial(params:any){
  try {
    const response = await $axios.post(postFontGroupMaterial,{group_id:params.group_id,page:params.page,search:params.search,uid:params.uid});
    return response.data.data;
  }catch (error){
    console.log(error)
  }

}

// 获取编辑器文字素材列表 -> 背景 -> 列表
export async function getBgMaterial(params:any){
  try {
    const response = await $axios.get(postBgMaterial);
    return response.data.data;
  }catch (error){
    console.log(error)
  }

}
// 获取编辑器背景素材列表 -> 背景 -> 列表->全部
export async function getBgGroupMaterial(params:any){
  try {
    const response = await $axios.post(postBgGroupMaterial,{group_id:params.group_id,page:params.page,search:params.search});
    return response.data.data;
  }catch (error){
    console.log(error)
  }

}
// 获取编辑器图库 -> 分类
export async function getImageGroup(params:any){
  try {
    const response = await $axios.get(postImageGroup);
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

// 获取编辑器图库 -> 列表
export async function getImageGroupList(params:any){
  try {
    const response = await $axios.post(postImageGroupList,{group_id:params.group_id,page:params.page,search:params.search,img_type:params.img_type,uid:params.uid});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}
// 获取编辑器元素素材->列表
export async function getMaterialList(params:any){
  try {
    const response = await $axios.post(postMaterialList,{uid:params.uid,page:params.page});
    return response.data.data;
  }catch (error){
    console.log(error)
  }

}
// 获取编辑器元素素材->列表->全部
export async function getGroupMaterial(params:any){
  try {
    const response = await $axios.post(postGroupMaterialList,{group_id:params.group_id,page:params.page,search:params.search,uid:params.uid});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}
// 获取模板temurl内容
export async function postTemplateTemurlinfo(params:any){
  try {
    const response = await $axios.post(postTemplateTemurlinfoUrl,{id:params.id});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

// 获取编辑器元素素材->列表->全部
export async function getFontList(params:any){
  try {
    const response = await $axios.post(postfontlist,{search:params.search,type:params.type});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

export async function savelikestate(params:any){
  try {
    const response = await $axios.post(postlikestate,{tid:params.tid,uid:params.uid,type:params.type});
    //console.log(response.data.code,'response.data.code');
    return response.data.code;
  }catch (error){
    console.log(error)
  }
}
export async function test(){
  //console.log('需要登录')
}
//获取图片上传我的分组列表
export async function getUploadGroupList(params:any){
  try{
    const response = await $axios.post(postUploadGroupList,{uid:params.uid,type:params.type})
    return response.data
  }catch (error){
  }
}
//图片上传添加分组
export async function getUploadGroupAdd(params:any) {
  try{
    const response = await $axios.post(postUploadGroupAdd,{uid:params.uid,title:params.title,type:params.type})
    return response.data
  }catch (error){
  }
}
//图片上传删除分组
export async function getUploadGroupDel(params:any) {
  try{
    const response = await $axios.post(postUploadGroupDel,{uid:params.uid,id:params.id})
    return response.data
  }catch (error){
  }
}
//图片上传修改分组名称
export async function getUploadGroupModify(params:any) {
  try{
    const response = await $axios.post(postUploadGroupModify,{uid:params.uid,title:params.title,id:params.id})
    return response.data
  }catch (error){
  }
}

// 获取上传临时凭证
export async function getUploadAuthMethod(params:any){
  try {
    const response = await $axios.get(getUploadAuth);
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}
//获取图片列表
export async function getUploadImgList(params:any){
  try {
    const response = await $axios.post(postUploadImgList,{uid:params.uid,group_id:params.group_id,page:params.page});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//添加图片
export async function getUploadImgAdd(params:any){
  try {
    const response = await $axios.post(postUploadImgAdd,{uid:params.uid,group_id:params.group_id,img:params.img});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
// //修改图片分组
export async function getUploadImgModify(params:any){
  try {
    const response = await $axios.post(postUploadImgModify,{uid:params.uid,group_id:params.group_id,img_id:params.img_id});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//删除图片
export async function getUploadImgDel(params:any){
  try {
    const response = await $axios.post(postUploadImgDel,{uid:params.uid,img_id:params.img_id});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取收藏列表
export async function getCollectionList(params:any){
  try {
    const response = await $axios.post(postCollectionList,{uid:params.uid,page:params.page,type:params.type});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
// 保存我的模板
export async function saveMyTemplates(params:any){
  try {
    const response = await $axios.post(postMyTemplate,{title:params.title,coverimg:params.coverimg,tempUrl:params.tempUrl,content:params.content,id:params.id,width:params.width,height:params.height});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
// 我的模版-我的设计图片列表
export async function getTemplateMyDesignList(params:any){
  try {
    const response = await $axios.post(postTemplateMyDesignList,{search:params.search,uid:params.uid,page:params.page,group_id:params.group_id,});
    return response.data;
  }catch (error){
    console.log(error)
  }
}

export async function getCanvasSizeList() {
  try {
    const  response = await $axios.get(postCanvasSizeList);
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
}
// 为你推荐列表
export async function getRecommendToYouList(){
  try {
    const response = await $axios.post(postRecommendToYouList);
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}
// 创建设计-获取列表信息
export async function getCreateDesignList(){
  try {
    const response = await $axios.post(postCreateDesignList);
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//我的模板 修改模板内容分组
export async function getMytTemplateGroup(params:any){
  try {
    const response = await $axios.post(postMytTemplateGroup,{tid:params.tid,group_id:params.group_id,});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//我的模板 修改模板内容名称
export async function getMyTemplateTitle(params:any){
  try {
    const response = await $axios.post(postMyTemplateTitle,{tid:params.tid,title:params.title,});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//我的模板 删除模板内容
export async function getDeleteMyTemplate(params:any){
  try {
    const response = await $axios.post(postDeleteMyTemplate,{tid:params.tid});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//素材首页获取图片列表
export async function getMaterialImgList() {
  try {
    const  response = await $axios.get(postMaterialImgList);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//素材首页获取素材列表
export async function getMaterialSvgList() {
  try {
    const  response = await $axios.get(postMaterialSvgList);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//素材首页获取文字列表
export async function getMaterialFontList() {
  try {
    const  response = await $axios.get(postMaterialFontList);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//素材中心获取搜索类型列表
export async function getMaterialGroup(params:any){
  try {
    const response = await $axios.post(postMaterialGroup,{type:params.type});
    //console.log(response,'接口')
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//素材中心获取搜索图片列表
export async function getSearchMaterialImg(params:any){
  try {
    const response = await $axios.post(postSearchMaterialImg,{type:params.type,search:params.search,group_id:params.group_id,page:params.page,banshi:params.banshi,color:params.color,sort:params.sort});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取用户信息
export async function getUserInfo() {
  try {
    const  response = await $axios.post(postUserInfo);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//获取用户订单信息
export async function getUserOrderList(params:any) {
  try {
    const  response = await $axios.post(postUserOrderList,{page:params.page});
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//获取用户发票信息
export async function getInvoiceList(params:any) {
  try {
    const  response = await $axios.post(postInvoiceList,{page:params.page});
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//用户申请发票
export async function getMyInvoice(params:any) {
  try {
    const  response = await $axios.post(postMyInvoice,{invoice_rise:params.invoice_rise,duty_paragraph:params.duty_paragraph,invoice_amount:params.invoice_amount});
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//用户发票限制
export async function getConfigLimit() {
  try {
    const  response = await $axios.post(postConfigLimit);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//热门搜索
export async function getHotSearch() {
  try {
    const  response = await $axios.post(postHotSearch);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
//获取我的权限
export async function postauthdo(params:any){
  try {
    const response = await $axios.post(postauth,{});
    return response.data;
  }catch (error){
    console.log(error)
  }
}

//记录下载日志
export async function postdowndo(params:any){
  try {
    const response = await $axios.post(postdown,{json:params.json,coverimg:params.coverimg});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取我的下载次数权限
export async function postdownnumdo(params:any){
  try {
    const response = await $axios.post(postdownnum,{});
    return response.data;
  }catch (error){
    console.log(error)
  }
}

//获取筛选项颜色列表
export async function getcolor(params:any){
  try {
    const response = await $axios.get(getcolorurl,{});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取换绑微信的状态
export async function getChangeBindingUserInfo(params:any){
  try {
    const response = await $axios.post(postChangeBindingUserInfo,{uniqid:params.uniqid});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取开票记录数据
export async function getInvoiceRecords(params:any){
  try {
    const response = await $axios.post(postInvoiceRecords);
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取素材中心首页详情
export async function getMaterialImgInfo(params:any){
  try {
    const response = await $axios.post(postMaterialImgInfo,{tid:params.tid,type:params.type});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取升级会员/支付数据
export async function getPriceList(){
  try {
    const response = await $axios.get(postPriceList);
    return response.data;
  }catch (error){
    console.log(error)
  }
}

//seo数据
export async function getSeoList(params){
  try {
    const response = await $axios.post(postSeoList,{gid:params.gid,id:params.id,nid:params.nid});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取支付二维码
export async function getPassageway(params:any){
  try {
    const response = await $axios.post(postPassageway,{type:params.type,pro_type:params.pro_type});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取支付状态
export async function getOrderStatus(){
  try {
    const response = await $axios.post(postorderStatus);
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//选择产品权限
export async function getCheckGroupAuth(params:any){
  try {
    const response = await $axios.post(postCheckGroupAuth,{pro_type:params.pro_type});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//获取会员到期
export async function getMemberPayInfo(params:any){
  try {
    const response = await $axios.post(postMemberPayInfo,{pro_type:params.pro_type});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
// 获取我的模板tmpurl
export async function postMyTemplateTemurlinfo(params:any){
  try {
    const response = await $axios.post(postMyTemplateTemurlinfoUrl,{id:params.id});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

//帮助中心获取首页列表
export async function getHelpList(){
  try {
    const response = await $axios.post(postHelpList);
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//帮助中心获取搜索列表
export async function getSearchHelpList(params:any){
  try {
    const response = await $axios.post(postSearchHelpList,{page:params.page,search:params.search});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
//帮助中心获取详情列表
export async function getHelpInfo(params:any){
  try {
    const response = await $axios.post(postHelpInfo,{id:params.id});
    return response.data;
  }catch (error){
    console.log(error)
  }
}
// 查询字体是否存在
export async function posthasfont(params:any){
  try {
    const response = await $axios.post(posthasfontUrl,{font:params.font});
    return response.data.code;
  }catch (error){
    console.log(error)
  }
}

export async function postTemplateGroup(){
  try {
    const response = await $axios.post(posttemplateGroupUrl,{});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

export async function postWapTemplateGroup(){
  try {
    const response = await $axios.post(postwaptemplateGroupUrl,{});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

export async function postWapHelpGroup(){
  try {
    const response = await $axios.post(postwaphelpGroupUrl,{});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

export async function postWapHelpList(params){
  try {
    const response = await $axios.post(postwaphelplistUrl,{id:params.id});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

export async function postWapwechaturls(){
  try {
    const response = await $axios.post(postwapwechatUrl,{});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}

export async function postWapwechatLogin(params){
  try {
    const response = await $axios.post(postwapwechatLoginUrl,{code:params.code});
    return response.data.data;
  }catch (error){
    console.log(error)
  }
}
