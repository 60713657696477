import { reactive, markRaw } from 'vue'
import { Object as FabricObject, ObjectRef, ActiveSelection } from 'fabric'

/**
 * 元素添加相应式属性
 */
const toRef = (object: FabricObject) => {
  // 如果已经有 ref，直接返回
  if (object.ref) return object

  const keyArr: (keyof ObjectRef)[] = [
    'id',
    'name',
    'hideOnLayer',
    'originX',
    'originY',
    'top',
    'left',
    'width',
    'height',
    'scaleX',
    'scaleY',
    'flipX',
    'flipY',
    'opacity',
    'angle',
    'skewX',
    'skewY',
    'hoverCursor',
    'moveCursor',
    'padding',
    'borderColor',
    'borderDashArray',
    'cornerColor',
    'cornerStrokeColor',
    'cornerStyle',
    'cornerDashArray',
    'centeredScaling',
    'centeredRotation',
    'fill',
    'fillRule',
    'globalCompositeOperation',
    'backgroundColor',
    'selectionBackgroundColor',
    'stroke',
    'strokeWidth',
    'strokeDashArray',
    'strokeDashOffset',
    'strokeLineCap',
    'strokeLineJoin',
    'strokeMiterLimit',
    'shadow',
    'borderScaleFactor',
    'minScaleLimit',
    'selectable',
    'evented',
    'visible',
    'hasControls',
    'hasBorders',
    'perPixelTargetFind',
    'includeDefaultValues',
    'lockMovementX',
    'lockMovementY',
    'lockRotation',
    'lockScalingX',
    'lockScalingY',
    'lockSkewingX',
    'lockSkewingY',
    'lockScalingFlip',
    'excludeFromExport',
    'objectCaching',
    'noScaleCache',
    'strokeUniform',
    'dirty',
    'paintFirst',
    'activeOn',
    'colorProperties',
    'inverted',
    'absolutePositioned',
    'bindReplaceState',
    'bindMoveX',
    'bindMoveY',
    'bindColorArr',
    'roundValue',
  ]

  if (object.isType('Rect')) {
    keyArr.push('rx', 'ry')
  }

  if (object.isType('Text', 'Textbox', 'i-text', 'ArcText', 'VerticalText', 'textbox')) {
    keyArr.push(
        'text',
        'charSpacing',
        'lineHeight',
        'fontSize',
        'fontWeight',
        'fontFamily',
        'fontStyle',
        'pathSide',
        'pathAlign',
        'underline',
        'overline',
        'linethrough',
        'textAlign',
        'direction',
        'bindTextType',
        'bindTextRowsNumber'
    )
  }

  // 创建响应式引用
  object.ref = reactive({}) as ObjectRef

  // 标记是否是 ActiveSelection
  const isActiveSelection = object instanceof ActiveSelection

  // 防止递归更新的标志
  let isUpdating = false

  // 初始化响应式值
  keyArr.forEach(<K extends keyof ObjectRef>(key: K) => {
    object.ref[key] = object[key]
  })

  // 定义属性
  keyArr.forEach(<K extends keyof ObjectRef>(key: K) => {
    Object.defineProperty(object, key, {
      get() {
        return this.ref[key]
      },
      set(value) {
        // 如果值没有变化或正在更新中，直接返回
        if (this.ref[key] === value || isUpdating) return

        // 更新响应式值
        this.ref[key] = value

        // 特殊处理 ActiveSelection
        if (isActiveSelection &&
            ['left', 'top', 'scaleX', 'scaleY', 'angle'].includes(key)) {

          // 使用 setTimeout 来延迟更新，避免递归
          setTimeout(() => {
            if (isUpdating) return
            isUpdating = true

            try {
              this.setCoords()
              const objects = this.getObjects()
              objects.forEach((child: FabricObject) => {
                if (child.ref) {
                  child.setCoords()
                  // 批量更新子元素属性
                  const props = {
                    left: child.left,
                    top: child.top,
                    scaleX: child.scaleX,
                    scaleY: child.scaleY,
                    angle: child.angle
                  }
                  Object.assign(child.ref, props)
                }
              })
            } finally {
              isUpdating = false
            }
          }, 0)
        }
      }
    })
  })

  // 处理组合内的元素
  if (object.isType('Group') || isActiveSelection) {
    setTimeout(() => {
      // 为现有的子元素添加响应式
      const objects = object.getObjects()
      objects.forEach((child: FabricObject) => {
        if (!child.ref) {
          toRef(child)
        }
      })

      // 只为 ActiveSelection 添加事件监听
      if (isActiveSelection) {
        let addRemoveInProgress = false

        const handleObjectAdded = (e: any) => {
          if (addRemoveInProgress) return
          addRemoveInProgress = true

          setTimeout(() => {
            const target = e.target
            if (target && !target.ref) {
              toRef(target)
            }
            addRemoveInProgress = false
          }, 0)
        }

        const handleObjectRemoved = (e: any) => {
          if (addRemoveInProgress) return
          addRemoveInProgress = true

          setTimeout(() => {
            if (e.target && e.target.ref) {
              e.target.ref = null
            }
            addRemoveInProgress = false
          }, 0)
        }

        object.on({
          'object:added': handleObjectAdded,
          'object:removed': handleObjectRemoved
        })
      }
    }, 0)
  }

  return object
}

export { toRef }