import { getUploadAuthMethod } from '@/src/api/apiconfig/main'
import OSS from 'ali-oss';
import {getLocal} from "@/src/utils/local";
import { Image } from 'fabric';

export const uploadImageToOSS = async (options='') => {

  let config = await getUploadAuthMethod({});

  const client = new OSS({
    region: config.region,
    accessKeyId: config.accessKeyId,
    accessKeySecret: config.accessKeySecret,
    bucket: config.bucket,
    stsToken: config.securityToken,
    endpoint:config.Endpoint,
    cname: true
  });
  let file = '';
  if(options.file === undefined){
     file = options;
  }else{
     file = options.file; // 拿到 file
  }

  let fileName = file.name.substr(0,file.name.lastIndexOf('.'))
  let date = new Date().getTime()

  const dates = new Date();
  const year = dates.getFullYear();
  const month = String(dates.getMonth() + 1).padStart(2, '0');
  const day = String(dates.getDate()).padStart(2, '0');
  const uid = getLocal('uid');
  const fileNameend = `${uid}/${year}${month}${day}/${date}_${fileName}`;

  const extension = file.name.split('.').pop();

  let fileNames = fileNameend+'.'+extension
  const result = await client.put(fileNames, file, {
    progress: (p) => {
       //console.log(Math.round(p * 100))
    },
  });
  //console.log(result,'result')
  return result;
}


// 检查图片是否可以加载的函数
export const checkImage = (url)=> {
  // return new Promise((resolve) => {
  //   const img = new Image();
  //   img.src = url;
  //   img.onload = () => resolve(true); // 图片加载成功
  //   img.onerror = () => resolve(false); // 图片加载失败
  // });

  return new Promise((resolve) => {
    // 使用 Fabric.js 的 Image.fromURL 方法加载图片
    Image.fromURL(url, {
      crossOrigin: 'anonymous' // 如果需要跨域支持，可以设置此选项
    })
        .then((img) => {
          resolve(true); // 图片加载成功
        })
        .catch((error) => {
          resolve(false); // 图片加载失败
        });
  });

}

// 主函数，循环对象数组并检查图片
export const  processImages = async(objects) =>{
  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];
    if (object.type === 'Image') {
      const accessible = await checkImage(object.src);
      if (!accessible) {
        object.src = 'http://skt.weipark.club/0/20241031/1730344892957_1.png';
        object.originSrc = "http://skt.weipark.club/0/20241031/1730344892957_1.png"// 替换为默认链接
      }
    } else if (object.type === 'Group' && object.objects) {
      await processImages(object.objects); // 递归处理子对象
    }
  }

  return objects;
}

// 自定义函数来检查图片是否可以加载
export const cuscheckImages = (objects) => {
  const promises = objects.map((obj) => {
    if (obj.type === "Image") {

      return new Promise((resolve) => {
        // 使用 Fabric.js 的 Image.fromURL 方法加载图片
        Image.fromURL(obj.src, {
          crossOrigin: 'anonymous' // 如果需要跨域支持，可以设置此选项
        })
            .then((img) => {
              resolve(obj); // 返回原对象
            })
            .catch((error) => {
              resolve(null); // 图片加载失败
            });
      });
      // return new Promise((resolve) => {
      //   const img = new Image();
      //   img.src = obj.src;
      //
      //   img.onload = () => {
      //     // 图片加载成功
      //     resolve(obj); // 返回原对象
      //   };
      //
      //   img.onerror = () => {
      //     // 图片加载失败
      //     console.warn(`Image not found: ${obj.src}`);
      //     resolve(null); // 返回 null 以表示该图片无法加载
      //   };
      // });
    } else if (obj.type === "Group" && obj.objects) {
      // 对于 Group 类型，递归检查子元素
      return cuscheckImages(obj.objects).then((validObjects) => {
        // 更新 Group 的子元素为有效的子元素
        obj.objects = validObjects;
        return obj; // 返回更新后的 Group 对象
      });
    } else {
      // 对于非图片对象，直接返回
      return Promise.resolve(obj);
    }
  });

  // 等待所有图片加载完成
  return Promise.all(promises).then((results) => {
    // 过滤掉 null 值（无法加载的图片）
    return results.filter((obj) => obj !== null);
  });
}
