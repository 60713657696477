import { defineNuxtPlugin } from '#app';
import { default as axios } from 'axios';
import { getLocal, removeLocal } from "@/src/utils/local";
import { ElMessage, ElMessageBox } from "element-plus";
import { useLoginStore } from '@/src/store/modules/login'
import { useRouter } from 'vue-router'
import { isMobile } from '@/src/utils/common'
export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()

  // 设置请求拦截器
  axios.interceptors.request.use(
      (config) => {
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers['Authorization'] = getLocal('token');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
  );

  // 设置响应拦截器
  axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
          console.log(error,'bbb');
        // 处理不同的错误状态码
        if (error.response) {
            console.log(error.response,'aaa');
          switch (error.response.status) {
            case 402:
              // 重定向到 404 页面
              router.push('/404')
              break;
            case 401:
                if(isMobile()){
                    ElMessageBox.alert('检测登录超时', '', {
                        confirmButtonText: '去登录',
                        callback: (action: Action) => {
                            removeLocal("token");
                            router.push('/mycenter');
                        },
                    })
                }else{
                    if(getLocal('token')){
                        const loginStore = useLoginStore()
                        loginStore.openoffline()
                        removeLocal("token");
                    }else{
                        const loginStore = useLoginStore()
                        loginStore.openloginstate()
                    }
                }
              break;
            default:
              // 处理其他错误
              ElMessage.error(error.response.data.message || '请求失败')
          }
        }
        return Promise.reject(error);
      }
  );

  nuxtApp.vueApp.config.globalProperties.$axios = axios;
});

