export default <RouterOptions>{
  routes:(_routes)=>{
    // @ts-ignore
    return [
      /*首页*/
      {
        path: '/',
        name:'/',
        component: () => import('@/pages/index.vue'),
      },
      /*图片编辑*/
      {
        path: '/designs/id/:id',
        name:'designs',
        component: () => import('@/pages/designs.vue'),
      },
      /*自定义图片编辑*/
      {
        path: '/designs/create/:create',
        name:'createdesigns',
        component: () => import('@/pages/designs.vue'),
      },
      /*我的模板编辑*/
      {
        path: '/designs/mycreate/:cid/id/:id',
        name:'mycreatedesigns',
        component: () => import('@/pages/designs.vue'),
      },
      /*模板中心检索*/
      {
        path: '/template/gid/:gid/id/:id/sid/:sid/cid/:cid/s/:s/nid/:nid',
        name:'templateCenterDetails',
        component: () => import('../src/components/templateCenter/templateCenter'),
      },
      /*模板中心详情*/
      {
        path: '/teminfo/id/:id',
        name:'templateInfo',
        component: () => import('../src/components/templateInfo/templatesinfo'),
        //component: () => import('../src/components/templateInfo/test'),
      },
      /*升级会员*/
      {
        path: '/vip',
        name:'member',
        component: () => import('../src/components/member/member.vue'),
      },
      /*个人中心*/
      {
        path: '/personalCenter/id/:id',
        name:'personalCenter',
        component: () => import('../src/components/personalCenter/personalCenter.vue'),
      },
      /*帮助中心*/
      {
        path: '/helpCenter',
        name:'helpCenter',
        component: () => import('../src/components/helpCenter/helpCenter.vue'),
      },
      /*帮助中心内容页*/
      {
        path: '/helpCenterSubpage/id/:id',
        name:'helpCenterSubpage',
        component: () => import('../src/components/helpCenter/helpCenterSubpage.vue'),
      },
      /*帮助中心搜索页*/
      {
        path:'/helpCenterSearch/value/:value',
        name:'helpCenterSearch',
        component:()=> import('../src/components/helpCenter/helpCenterSearch.vue')
      },
      /*模板中心*/
      {
        path: '/templateCenter',
        name:'templateCenter',
        component: () => import('../src/components/templateCenter/templateCenter'),
      },
      /*我的设计*/
      {
        path:'/myDesign',
        name:'myDesign',
        component: () => import('../src/components/myDesign/myDesign'),
      },
      /*创建设计*/
      {
        path:'/createDesign',
        name:'createDesign',
        component: () => import('../src/components/createDesign/index'),
      },
      /*素材中心*/
      {
        path:'/materialCenter',
        name:'materialCenter',
        component: () => import('../src/components/materialCenter/materialRetrieval'),
      },
      // /*素材中心检索*/
      // {
      //   path:'/materialCenterSerarch/info/:info',
      //   name:'materialRetrieval',
      //   component: () => import('../src/components/materialCenter/materialRetrieval'),
      // },
      /*素材中心详情页面*/
      {
        path:'/detailsMaterialCenter/id/:id/type/:type',
        name:'detailsMaterialCenter',
        component: () => import('../src/components/materialCenter/detailsMaterialCenter'),
      },
      /*个人中心手机页面*/
      {
        path:'/mycenter',
        name:'mycenter',
        component: () => import('../src/components/wap/mycenter/mycenterwap'),
      },
      {
        path:'/myorder',
        name:'myorder',
        component: () => import('../src/components/wap/mycenter/myorder'),
      },
      {
        path:'/myinvoice',
        name:'myinvoice',
        component: () => import('../src/components/wap/mycenter/myinvoice'),
      }
    ]
  }
}