import { defineStore } from 'pinia'
export interface FontState {
    loadfont: Array,
}
export const useFontStore = defineStore('font',{
    state: (): FontState => ({
        loadfont:[],//已加载字体
    }),
    getters:{
        hasFont(state){
            return (item) => state.loadfont.includes(item)
        },
    },
    actions: {
        addFont(item:any){
            this.loadfont.push(item)
        }
    },
})