import { default as designsglaorVykAKMeta } from "D:/phpstudy_pro/wwwroot/gitee_sskt_customer/pages/designs.vue?macro=true";
import { default as indexTriDZCJqKgMeta } from "D:/phpstudy_pro/wwwroot/gitee_sskt_customer/pages/index.vue?macro=true";
import { default as templateCenterHnoQTvUTWdMeta } from "D:/phpstudy_pro/wwwroot/gitee_sskt_customer/pages/templateCenter.vue?macro=true";
export default [
  {
    name: designsglaorVykAKMeta?.name ?? "designs",
    path: designsglaorVykAKMeta?.path ?? "/designs",
    meta: designsglaorVykAKMeta || {},
    alias: designsglaorVykAKMeta?.alias || [],
    redirect: designsglaorVykAKMeta?.redirect,
    component: () => import("D:/phpstudy_pro/wwwroot/gitee_sskt_customer/pages/designs.vue").then(m => m.default || m)
  },
  {
    name: indexTriDZCJqKgMeta?.name ?? "index",
    path: indexTriDZCJqKgMeta?.path ?? "/",
    meta: indexTriDZCJqKgMeta || {},
    alias: indexTriDZCJqKgMeta?.alias || [],
    redirect: indexTriDZCJqKgMeta?.redirect,
    component: () => import("D:/phpstudy_pro/wwwroot/gitee_sskt_customer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: templateCenterHnoQTvUTWdMeta?.name ?? "templateCenter",
    path: templateCenterHnoQTvUTWdMeta?.path ?? "/templateCenter",
    meta: templateCenterHnoQTvUTWdMeta || {},
    alias: templateCenterHnoQTvUTWdMeta?.alias || [],
    redirect: templateCenterHnoQTvUTWdMeta?.redirect,
    component: () => import("D:/phpstudy_pro/wwwroot/gitee_sskt_customer/pages/templateCenter.vue").then(m => m.default || m)
  }
]