import { IText as OriginIText, classRegistry,Textbox } from "fabric"
import { StrokeItem } from "@/src/types/common"
import {WorkSpaceDrawType} from "~/src/configs/canvas";
import type {GraphemeBBox, TextLinesInfo} from "fabric/src/shapes/Text/Text";
import {type GraphemeData, StyleMap} from "fabric/src/shapes/Textbox";

export class IText extends OriginIText {
  public strokes?: StrokeItem[];

  constructor(text: string, options?: any) {
    super(text, options);
    this.strokes = options.strokes
    this.on('changed',this.onTextChanged)
  }
  declare firstwidth: null;

  onTextChanged(){
    this.autoWrapText();
    this.canvas.renderAll();
  }
  // 自动换行
  autoWrapText(){
    if(this.firstwidth == null){
      this.firstwidth = this.width;
    }
    const textbox = new Textbox(this.text,{splitByGrapheme:true,fontSize:this.fontSize,width:this.firstwidth});
    const newtext = textbox._splitTextIntoLines(this.text);
    this.set('text',newtext.lines.join('\n'))
  }


  _renderChar(method: "fillText" | "strokeText", ctx: CanvasRenderingContext2D, lineIndex: number, charIndex: number, _char: string, left: number, top: number): void {
    if (this.strokes) {
      for (let i = 0; i < this.strokes.length; i++) {
        const item = this.strokes[i]
        ctx.save();
        ctx.strokeStyle = item.stroke;
        ctx.lineWidth = item.strokeWidth;
        ctx.strokeText(_char, left, top);
        ctx.restore()
      }
    }
    super._renderChar(method, ctx, lineIndex, charIndex, _char, left, top)
  }

}

classRegistry.setClass(IText, 'IText')