import { defineStore } from 'pinia'
export interface LoginState {
  offlinestate: boolean,
  loginshowstate:boolean,
  newuserstate:boolean,
  template_g_name:String,
  template_n_name:String,
  template_h_name:String,
  temolate_c_name:String,
  temolate_s_name:String,
}
export const useLoginStore = defineStore('login',{
  state: (): LoginState => ({
    offlinestate:false,//下线通知状态
    loginshowstate:false,//登录页面显示状态
    newuserstate:false,//新手知道状态
    template_g_name:'全部',//模板当前选中类别
    template_n_name:'全部',//模板当前选中场景
    template_h_name:'全部',//模板当前选中行业
    temolate_c_name:'全部',//模板当前选中颜色
    temolate_s_name:'全部',//模板当前选中风格

  }),
  actions: {
    openoffline(){
      this.offlinestate = true;
    },
    closeoffline(){
      this.offlinestate = false;
    },
    changeoffline(){
      this.offlinestate = !this.offlinestate
    },
    openloginstate(){
      this.loginshowstate = true;
    },
    closeloginstate(){
      this.loginshowstate = false;
    },
    opennewuserstate(){
      this.newuserstate = true;
    },
    closenewuserstate(){
      this.newuserstate = false;
    }
  },
})