import { storeToRefs } from "pinia";
import { unref } from "vue";
import { useFabricStore, useTemplatesStore } from "@/src/store";
import { useMainStore } from "@/src/store/modules/main";
import { RightStates, ElementNames } from "@/src/types/elements";
import { nanoid } from "nanoid";
import { QRCodeElement, QRCodeOption } from "@/src/types/canvas";
import { getImageSize } from "@/src/utils/image";
import { isMobile } from '@/src/utils/common'
import {
  Object as FabricObject,
  Path,
  classRegistry,
  XY,
  util,
  Image as FabricImage,
  Group,
  loadSVGFromString,
  Gradient,
  Rect,
  Point,
  Textbox as FabricTextbox,
  loadSVGFromURL
} from "fabric";
import { Group as MyGroup } from '@/src/extension/object/Group'
import { Textbox } from "@/src/extension/object/Textbox";
import { LinePoint } from "@/src/types/elements";
import { Image } from "@/src/extension/object/Image";
import { QRCode } from "@/src/extension/object/QRCode";
import { BarCode } from "@/src/extension/object/BarCode";
import { ArcText } from '@/src/extension/object/ArcText'
import { VerticalText } from '@/src/extension/object/VerticalText'
import JsBarcode from "jsbarcode";
import { i18nObj } from "@/src/plugin/i18n/index"
import useCenter from "@/src/views/Canvas/useCenter";
import useCanvas from "@/src/views/Canvas/useCanvas";
import useCanvasZindex from "./useCanvasZindex";
import {WorkSpaceDrawType} from "~/src/configs/canvas";
import { getObjectsBoundingBox  } from '@/src/utils/common'
import {IText} from "~/src/extension/object/IText";


export default () => {

  const mainStore = useMainStore();
  const templatesStore = useTemplatesStore();
  const { setZindex } = useCanvasZindex();
  const { t } = i18nObj().global;
  const { rightState, systemFonts } = storeToRefs(mainStore);

  const renderCanvas = (element: FabricObject,iscreate=false) => {
    const [canvas] = useCanvas();
    canvas.add(element);
    canvas.setActiveObject(element);
    computedObjectScale(iscreate);
    if(isMobile()){
      canvas.discardActiveObject()
    }
    canvas.viewportCenterObject(element);
    rightState.value = RightStates.ELEMENT_STYLE;
    setZindex(canvas);
    canvas.renderAll();
    templatesStore.modifedElement();
    mainStore.setDrawAreaFocus(true)
  };

  const createTextElement = (fontSize: number, textStyle = "transverse", textHollow = false, textValue = t("default.textValue"),iscreate=false) => {

    if (textStyle === "direction") {
      createVerticalTextElement(fontSize, textHollow, textValue,iscreate)
      return
    }
    const { centerPoint } = useCenter();
    //debugger;Textbox,IText
    const textBoxElement = new FabricTextbox(textValue, {
      id: nanoid(10),
      left: centerPoint.x,
      top: centerPoint.y,
      fontSize:fontSize,
      //fontFamily: systemFonts.value[0].value,
      fontFamily: '思源黑体-Regular',
      fillType: 0,
      hasControls: true,
      hasBorders: true,
      fontWeight: "normal",
      charSpacing: 0,
      opacity: 1,
      lineHeight: 1.3,
      originX: "left",
      originY: "top",
      textAlign: "justify-center",
      name: ElementNames.TEXTBOX,
      splitByGrapheme: true,
      width: fontSize * textValue.length,
    });
    //textBoxElement.set({left: textBoxElement.left - textBoxElement.width / 2, top: textBoxElement.top - textBoxElement.height / 2})
    if (textHollow) {
      textBoxElement.fill = "";
      textBoxElement.stroke = "black";
      textBoxElement.strokeWidth = 1;
    }
    renderCanvas(textBoxElement,iscreate);
  };

  const createArcTextElement = (fontSize: number, textStyle = 'transverse', textHollow = false, textValue = '双击修改文字') => {
    const { centerPoint } = useCenter()
    
    const textBoxElement = new ArcText(textValue, {
      id: nanoid(10),
      left: centerPoint.x,
      top: centerPoint.y,
      fontSize:fontSize,
      //fontFamily: systemFonts.value[0].value,
      fontFamily: '思源黑体-Regular',
      fillType: 0,
      hasControls: true,
      hasBorders: true,
      fontWeight: 'normal',
      charSpacing: 3,
      opacity: 1,
      lineHeight: 1.3,
      originX: 'left',
      originY: 'top',
      textAlign: 'justify-center',
      name: ElementNames.TEXTBOX,
      splitByGrapheme: textStyle === 'direction' ? true : false,
    })
    textBoxElement.set({left: textBoxElement.left - textBoxElement.width / 2, top: textBoxElement.top - textBoxElement.height / 2})
    if (textHollow) {
      textBoxElement.fill = ''
      textBoxElement.stroke = 'black'
      textBoxElement.strokeWidth = 1 
    }
    renderCanvas(textBoxElement)
  }

  const createVerticalTextElement = (fontSize: number, textHollow = false, textValue = '双击修改文字',iscreate=false) => {
    const { centerPoint } = useCenter()
    
    const textBoxElement = new VerticalText(textValue, {
      id: nanoid(10),
      left: centerPoint.x,
      top: centerPoint.y,
      fontSize:fontSize,
      //fontFamily: systemFonts.value[0].value,
      fontFamily: '思源黑体-Regular',
      fillType: 0,
      hasControls: true,
      hasBorders: true,
      fontWeight: 'normal',
      charSpacing: 3,
      opacity: 1,
      lineHeight: 1.3,
      originX: 'left',
      originY: 'top',
      name: ElementNames.VERTICALTEXT,
    })
    textBoxElement.set({left: textBoxElement.left - textBoxElement.width / 2, top: textBoxElement.top - textBoxElement.height / 2})
    if (textHollow) {
      textBoxElement.fill = "";
      textBoxElement.stroke = "black";
      textBoxElement.strokeWidth = 1;
    }
    renderCanvas(textBoxElement,iscreate)
  }

  const createPathElement = (path: string, left?: number, top?: number) => {
    const { centerPoint } = useCenter();
    const pathElement = new Path(path, {
      id: nanoid(10),
      left: left ? left : centerPoint.x,
      top: top ? top : centerPoint.y,
      hasControls: true,
      hasBorders: true,
      opacity: 1,
      originX: "left",
      originY: "top",
      fill: "#0099CC",
      name: ElementNames.PATH,
    });
    pathElement.left -= pathElement.width / 2;
    pathElement.top -= pathElement.height / 2;
    renderCanvas(pathElement);
  };

  const createLineElement = (path: XY[], startStyle: LinePoint, endStyle: LinePoint, strokeDashArray?: [number, number]) => {
    // const { centerPoint } = useCenter()
    // const lineElement = new Line([0, 0, 300, 0], {
    //   id: nanoid(10),
    //   left: centerPoint.x,
    //   top: centerPoint.y,
    //   strokeWidth: 4,
    //   stroke: 'green',
    //   scaleX: 1,
    //   scaleY: 1,
    //   originX: 'left',
    //   originY: 'top',
    //   transparentCorners: false,
    // })
    // renderCanvas(lineElement)
    // canvas.add(lineElement)
    // canvas.setActiveObject(lineElement)
    // rightState.value = RightStates.ELEMENT_STYLE
    // templatesStore.modifedElement()
    // setZindex(canvas)
    createPolylineElement(path, startStyle, endStyle, strokeDashArray);
    // createArrowElement(path)
  };

  const createPolylineElement = (path: XY[], startStyle: LinePoint, endStyle: LinePoint, strokeDashArray?: [number, number]) => {
    const { centerPoint } = useCenter();
    // const points = [ { x: 0, y: 0 }, { x: 200, y: 0 } ]
    const Polyline = classRegistry.getClass("Polyline");

    const element = new Polyline(path, {
      id: nanoid(10),
      left: centerPoint.x,
      top: centerPoint.y,
      strokeWidth: 4,
      stroke: "pink",
      fill: "",
      scaleX: 1,
      scaleY: 1,
      originX: "left",
      originY: "top",
      startStyle,
      endStyle,
      hasBorders: false,
      objectCaching: false,
      transparentCorners: false,
      strokeDashArray,
      name: ElementNames.LINE,
    });
    renderCanvas(element);
  };

  const createImageElement = (url: string) => {
    const { zoom } = storeToRefs(useFabricStore());
    const { currentTemplateWidth, currentTemplateHeight } = storeToRefs(useTemplatesStore());
    const { centerPoint } = useCenter();
    const [canvas] = useCanvas();
    getImageSize(url).then(async ({ width, height }) => {
      const scale = height / width;
      let imageScale = 1;
      if (scale < zoom.value && width > currentTemplateWidth.value) {
        imageScale = currentTemplateWidth.value / width;
      } else if (height > currentTemplateHeight.value) {
        imageScale = currentTemplateHeight.value / height;
      }
      let imageScalew = currentTemplateWidth.value / width - 0.1;
      let imageScaleh = currentTemplateHeight.value / height - 0.1;
      let minimageScale = Math.min(imageScalew, imageScaleh);
      const imageElement = await Image.fromURL(url, {
        id: nanoid(10),
        angle: 0,
        //left: centerPoint.x - (width * imageScale) / 2,
        //top: centerPoint.y - (height * imageScale) / 2,
        //scaleX: minimageScale,
        //scaleY: minimageScale,
        hasControls: true,
        hasBorders: true,
        opacity: 1,
        originX: "left",
        originY: "top",
        //borderColor: "#ff8d23",
        name: ElementNames.IMAGE,
        crossOrigin: "anonymous",
      });
      //imageElement.scaleToWidth(canvas.width /2 )
      //imageElement.scaleToHeight(canvas.height / 2 )
      renderCanvas(imageElement);
    });
  };

  const createBgimgElement = (url: string) => {
    const { zoom } = storeToRefs(useFabricStore());
    const { currentTemplateWidth, currentTemplateHeight } = storeToRefs(useTemplatesStore());
    const { centerPoint } = useCenter();
    const [canvas] = useCanvas();
    const bgimginfo = templatesStore.getBackgroundImage();
    console.log(bgimginfo.scaleX,'bgimginfo');
    getImageSize(url).then(async ({ width, height }) => {
      const scale = height / width;
      let scaleX = currentTemplateWidth.value / width;
      let scaleY = currentTemplateHeight.value / height;
      let imageScale = 1;
      const imageElement = await Image.fromURL(url, {
        id: nanoid(10),
        angle: 0,
        left: 0,
        top: 0,
        scaleX: bgimginfo.scaleX,
        scaleY: bgimginfo.scaleY,
        hasControls: true,
        hasBorders: true,
        opacity: 1,
        originX: "left",
        originY: "top",
        //borderColor: "#ff8d23",
        name: ElementNames.IMAGE,
        crossOrigin: "anonymous",
        width:bgimginfo.width,
        height:bgimginfo.height
      });
      imageElement.mytype = 'image';

      const [canvas] = useCanvas();
      canvas.add(imageElement);
      canvas.setActiveObject(imageElement);
      //computedObjectScale(iscreate);
      //canvas.viewportCenterObject(imageElement);
      rightState.value = RightStates.ELEMENT_STYLE;
      canvas.sendObjectToBack(imageElement);
      setZindex(canvas);
      canvas.renderAll();
      templatesStore.modifedElement();
      mainStore.setDrawAreaFocus(true)

      //renderCanvas(imageElement);

    });
  };

  const createQRCodeElement = async (url: string, codeOption: QRCodeOption, codeContent?: string) => {
    const { centerPoint } = useCenter();
    // const QRCode = classRegistry.getClass('QRCode')
    const codeObject = (await QRCode.fromURL(url, {
      id: nanoid(10),
      name: ElementNames.QRCODE,
      angle: 0,
      left: centerPoint.x,
      top: centerPoint.y,
      hasControls: true,
      hasBorders: true,
      opacity: 1,
      originX: "left",
      originY: "top",
      borderColor: "#ff8d23",
      codeContent,
      codeOption,
      crossOrigin: "anonymous",
    })) as QRCodeElement;
    //console.log("codeObject", codeObject);
   // codeObject.left -= codeObject.width / 2;
    //codeObject.top -= codeObject.height / 2;
    renderCanvas(codeObject);
  };

  const createBarCodeElement = async (url: string, codeContent: string, codeOption: JsBarcode.BaseOptions) => {
    const { centerPoint } = useCenter();
    // const Barcode = classRegistry.getClass('BarCode')
    const barcodeObject = await BarCode.fromURL(url, {
      id: nanoid(10),
      name: ElementNames.BARCODE,
      angle: 0,
      left: centerPoint.x,
      top: centerPoint.y,
      hasControls: true,
      hasBorders: true,
      opacity: 1,
      originX: "left",
      originY: "top",
      borderColor: "#ff8d23",
      codeContent,
      codeOption,
      crossOrigin: "anonymous",
    });
    barcodeObject.left -= barcodeObject.width / 2;
    barcodeObject.top -= barcodeObject.height / 2;

    renderCanvas(barcodeObject);
  };

  const createVideoElement = (url: string) => {
    const { centerPoint } = useCenter();
    const [canvas] = useCanvas();
    const videoEl = document.createElement("video");
    videoEl.loop = true;
    videoEl.crossOrigin = "anonymous";
    videoEl.controls = true;
    videoEl.style.display = "none";

    const sourceEl = document.createElement("source");
    sourceEl.src = url;
    videoEl.appendChild(sourceEl);

    videoEl.addEventListener("loadeddata", function () {
      videoEl.width = videoEl.videoWidth;
      videoEl.height = videoEl.videoHeight;
      const videoElement = new FabricImage(videoEl, {
        left: centerPoint.x,
        top: centerPoint.y,
        originX: "center",
        originY: "center",
        objectCaching: false,
      });
      canvas.add(videoElement);
      const viedoSource = videoElement.getElement() as any
      viedoSource.play();
      util.requestAnimFrame(function render() {
        canvas.renderAll();
        util.requestAnimFrame(render);
      });
    });
  };

  const imageObjects = [];
  const textObjects = [];

  const loadFile = async (data: []) => {
    const [canvas] = useCanvas();

    if(data.type == 'ArcText'){
      loadArcText(data)
    }else if(data.type == 'Group'){
      var group = await MyGroup.fromObject(data);
      renderCanvas(group)
    }else if(data.type == 'IText' || data.type == 'Textbox'){
      loadIText(data)
    }else if(data.type == 'VerticalText'){
      loadVerticalText(data)
    }
  }

  // 加载环形文字
  const loadArcText = (data) =>{
    delete data.type;
    delete data.version;
    data.id=nanoid(10);
    const textBoxElement = new ArcText(data.text, data)
    renderCanvas(textBoxElement)
  }
  // 加载普通文字
  const loadIText = (data) =>{
    const { centerPoint } = useCenter()
    //data.left = centerPoint.x;
    //data.top  =  centerPoint.y;
    //data.left = data.left - data.width / 2;
    //data.top  = data.top - data.height / 2
    delete data.type;
    delete data.version;
    data.id=nanoid(10);
    // 渐变色处理
    if(data.fillType == 2){
      const gradient = new Gradient({
        type: data.fill.type,
        colorStops: data.fill.colorStops,
        coords: data.fill.coords,
        offsetX: data.fill.offsetX,
        offsetY: data.fill.offsetY,
        gradientUnits: "pixels",
      });
      data.fill = gradient;
    }
    const textBoxElement = new Textbox(data.text, data)
    renderCanvas(textBoxElement)
  }
  // 加载竖版文字
  const loadVerticalText = (data) => {
    const { centerPoint } = useCenter()
    //data.left = centerPoint.x;
    //data.top  =  centerPoint.y;
    //data.left = data.left - data.width / 2;
    //data.top  = data.top - data.height / 2
    delete data.type;
    delete data.version;
    data.id=nanoid(10);
    const textBoxElement = new VerticalText(data.text, data)
    renderCanvas(textBoxElement)
  }
  // 加载组文字
  const loadGroups = async(item) =>{

    if (item.type == 'Image' || item.type == 'image') {
      delete item.type;
      delete item.version;
      item.id = nanoid(10);
      //item.lockMovementX = true;
      //item.lockMovementY = true;
      //item.selectable=false
      const imageElement = await Image.fromURL(item.originSrc,item);
      imageObjects.push(imageElement);
    } else if (item.type == 'ArcText') {
      delete item.type;
      delete item.version;
      item.id = nanoid(10);
      //item.lockMovementX = true;
      //item.lockMovementY = true;
      const textBoxElement_j = new ArcText(item.text, item)
      textObjects.push(textBoxElement_j);
    } else if (item.type == 'IText' || item.type == 'Textbox') {
      delete item.type;
      delete item.version;
      item.id = nanoid(10);
      //item.lockMovementX = true;
      //item.lockMovementY = true;
      const textBoxElement_j = new Textbox(item.text, item)
      textObjects.push(textBoxElement_j);
    } else if (item.type == 'VerticalText') {
      delete item.type;
      delete item.version;
      item.id = nanoid(10);
      //item.lockMovementX = true;
      //item.lockMovementY = true;
      const textBoxElement_j = new VerticalText(item.text, item)
      textObjects.push(textBoxElement_j);
    }
  }
  // 加载svg文件
  const loadSvgStr = async(url,type,path)=>{

    const [canvas] = useCanvas();
    const { centerPoint } = useCenter()
    if(type=='1'){
      const response = await fetch(url);
      const dataText = await response.text();
      const content = await myloadSVGFromString(dataText)
      const aaa = await loadSVGFromURL(url);

      var group = new fabric.Group([...content.objects]);
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const centerX =  centerPoint.x;
      const centerY =  centerPoint.y;

      group.set({
        id: nanoid(10),
        name: ElementNames.GROUP,
        scaleX:0.2,
        scaleY:0.2
      });
      group.setCoords();
      canvas.viewportCenterObject(group);
      canvas.add(group)
      canvas.renderAll()
    }else if(type == '3'){
      const response = await fetch(url);
      const dataText = await response.text();
      const dataobejct = JSON.parse(dataText);
      delete dataobejct.type;

      const pathElement = new Path(dataobejct.path, {
        id: nanoid(10),
        left: centerPoint.x,
        top: centerPoint.y,
        hasControls: true,
        hasBorders: true,
        strokeWidth:dataobejct.strokeWidth,
        strokeDashArray:dataobejct.strokeDashArray,
        strokeLineCap:dataobejct.strokeLineCap,
        strokeDashOffset:dataobejct.strokeDashOffset,
        strokeLineJoin:dataobejct.strokeLineJoin,
        strokeUniform:dataobejct.strokeUniform,
        strokeMiterLimit:dataobejct.strokeMiterLimit,
        stroke:dataobejct.stroke,
        opacity: dataobejct.opacity,
        originX: "left",
        originY: "top",
        fill: dataobejct.fill,
        background:dataobejct.background,
        name: ElementNames.PATH,
      });
      renderCanvas(pathElement);
    }else if(type == '4'){
      const response = await fetch(url);
      const dataText = await response.text();
      const dataobejct = JSON.parse(dataText);
      delete dataobejct.type;
      // 渐变色处理
      if(dataobejct.fillType == 2){
        var fill = new Gradient(dataobejct.fill);
        dataobejct.fill = fill;
      }
      dataobejct.id = nanoid(10);
      const RectElement = new Rect(dataobejct);
      RectElement.mytype = 'rect';
      const [canvas] = useCanvas();
      // 先将rect的缩放变为1，然后宽和高需要重新计算
      RectElement.width = RectElement.width * RectElement.scaleX;
      RectElement.height = RectElement.height * RectElement.scaleY;
      RectElement.scaleX = 1;
      RectElement.scaleY = 1;

      RectElement.on('modified', function() {

        // 获取当前宽度和高度
        const currentWidth = RectElement.width;
        const currentHeight = RectElement.height;
        const scaleX = RectElement.scaleX;
        const scaleY = RectElement.scaleY;
        RectElement.set({
          width: currentWidth * scaleX,
          height: currentHeight * scaleY,
          scaleX:1,
          scaleY:1,
        })
        //RectElement.setCoords();
      });

      canvas.add(RectElement);
      canvas.setActiveObject(RectElement);
      computedObjectScale(false)
      if(isMobile()){
        canvas.discardActiveObject()
      }
      canvas.viewportCenterObject(RectElement);
      rightState.value = RightStates.ELEMENT_STYLE;
      setZindex(canvas);
      canvas.renderAll();
      templatesStore.modifedElement();
      mainStore.setDrawAreaFocus(true)
    }else if(type == '5'){
      const response = await fetch(url);
      const dataText = await response.text();
      const dataobejct = JSON.parse(dataText);
      delete dataobejct.type;
      // 渐变色处理
      if(dataobejct.fillType == 2){
        var fill = new Gradient(dataobejct.fill);
        dataobejct.fill = fill;
      }
      dataobejct.id = nanoid(10);
      dataobejct.padding = 20;
      dataobejct.hasBorders = true;
      const { centerPoint } = useCenter();
      const Polyline = classRegistry.getClass("Polyline");
      const Lineelement = new Polyline(dataobejct.points, dataobejct);


      Lineelement.mytype = 'line';
      const [canvas] = useCanvas();
      canvas.add(Lineelement);
      canvas.setActiveObject(Lineelement);
      computedObjectScale(false);
      if(isMobile()){
        canvas.discardActiveObject()
      }
      canvas.viewportCenterObject(Lineelement);
      rightState.value = RightStates.ELEMENT_STYLE;
      setZindex(canvas);
      canvas.renderAll();
      templatesStore.modifedElement();
      mainStore.setDrawAreaFocus(true)
    }else{
      createImageElement(url);
    }
  }

  // 计算元素缩放比
  const computedObjectScale = (iscreate=false) =>{

    const [canvas] = useCanvas();
    const workSpaceDraw = canvas.getObjects().filter((item) => item.id === WorkSpaceDrawType)[0];
    const canvasWidth =  canvas.getWidth();
    const canvasHeight = canvas.getHeight();
    const zoom = canvas.getZoom()
    // 计算缩放后的画布尺寸
    const scaleEndCanvasWidth = workSpaceDraw.width * zoom;
    const scaleEndCanvasHeight = workSpaceDraw.height * zoom;
    const ActiveObject = canvas.getActiveObject();
    if(!ActiveObject){
      return
    }
    const ActiveObjectWidth = ActiveObject.width * zoom;
    const ActiveObjectHeight = ActiveObject.height * zoom;
    // 计算元素缩放比
    const ActiveObjectScaleX = scaleEndCanvasWidth / ActiveObjectWidth;
    const ActiveObjectScaleY = scaleEndCanvasHeight / ActiveObjectHeight;
    var scale = Math.min(ActiveObjectScaleX, ActiveObjectScaleY)
    if(iscreate){
      const nowfontsize = ActiveObject.fontSize;
      if(nowfontsize == 80){
        scale = scale * 0.7;
      }else if(nowfontsize == 60){
        scale = scale * 0.5;
      }else{
        scale = scale * 0.3;
      }
    }else{
      scale = scale * 0.7;
    }

    ActiveObject.scale(scale)
  }
  return {
    createTextElement,
    createPathElement,
    createLineElement,
    createImageElement,
    createQRCodeElement,
    createBarCodeElement,
    createVideoElement,
    createArcTextElement,
    createVerticalTextElement,
    loadFile,//导入文字素材模板
    loadSvgStr,//导入svg素材
    createBgimgElement,//创建背景图片
    computedObjectScale,//计算元素缩放
  };
};
