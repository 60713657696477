import { createPinia } from "pinia"
import { setupI18n } from '@/src/plugin/i18n/index'
import "@/src/extension/index"
import SvgIcon from "@/src/icons/index"
import Icon from "@/src/plugin/icon"
import Component from "@/src/plugin/component"
import Directive from "@/src/plugin/directive/index"
import 'wc-waterfall'
//import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
export default defineNuxtPlugin((nuxtApp)=>{
    const app = nuxtApp.vueApp;
    setupI18n(app)
    app.use(createPinia());
    app.use(Icon);
    app.use(SvgIcon);
    app.use(Component);
    app.use(Directive);


     if (process.client) {
        //app.use(ElementPlus)
     }
})