// @ts-nocheck
import { Template } from "@/src/types/canvas"
export const Templates: Template[] = [
  {
    "id": "2BGrF5iVye",
    "background": "rgba(255,255,255,0)",
    "objects": [
      {
        "rx": 0,
        "ry": 0,
        "id": "WorkSpaceDrawType",
        "name": "rect",
        "color": "rgba(146,51,139,1)",
        "padding": 0,
        "fill": "#fff",
        "selectable": false,
        "evented": false,
        "fillType": 0,
        "lockMovementX": false,
        "lockMovementY": false,
        "objectCaching": true,
        "transparentCorners": false,
        "hasBorders": true,
        "globalCompositeOperation": "source-over",
        "type": "Rect",
        "version": "6.0.0-beta12",
        "originX": "left",
        "originY": "top",
        "left": 0,
        "top": 0,
        "width": 1242,
        "height": 2208,
        "stroke": "rgba(255,255,255,1)",
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeUniform": false,
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "skewX": 0,
        "skewY": 0
      }
    ],
    "workSpace": {
      "fillType": 0,
      "left": 0,
      "top": 0,
      "angle": 0,
      "scaleX": 1,
      "scaleY": 1,
      "fill": "#fff",
      "imageURL": "",
      "imageSize": "contain"
    },
    "zoom": 1,
    "width": "1242",
    "height": "2208"
  }
];
