// 获取海报列表
export const posterListUri = '/api/product/get_all_template'

// 获取海报分组
export const postGroupUri = '/api/product/get_template_group'

//获取模板详情列表
export const postTemplateListUri = '/api/product/get_template_content'

//获取微信二维码
export const postWechatUri = 'api/wechat/get_login_qrcode'

//获取微信登录
export const postWechatLoginUri = "api/login/checkunid"
//获取编辑器字体素材列表
export const postFontMaterial = '/api/product/get_font_material'
//获取编辑器字体素材列表->全部
export const postFontGroupMaterial = '/api/product/get_group_font_material'
//获取编辑器背景列表
export const postBgMaterial = '/api/product/get_bg_material'
// 获取编辑器背景列表->全部
export const postBgGroupMaterial = '/api/product/get_group_bg_material'
// 获取编辑器图库->分类
export const postImageGroup = '/api/product/get_img_group'
// 获取编辑器图库->列表
export const postImageGroupList = '/api/product/get_img_group_list'
// 获取编辑器元素素材->列表
export const postMaterialList = '/api/product/get_material_list'
// 获取编辑器元素素材->列表->全部
export const postGroupMaterialList = '/api/product/get_group_material_list'
// 获取模板temurl内容
export const postTemplateTemurlinfoUrl = '/api/product/get_template_info'
// 获取字体列表
export const postfontlist = '/api/product/get_font'
// 修改收藏状态
export const postlikestate = '/api/product/save_like_state'
//图片上传->获取我的分组列表
export const postUploadGroupList = '/api/product/get_my_group'
//图片上传->修改分组名称
export const postUploadGroupModify = 'api/productdo/save_group'
//图片上传->删除分组
export const postUploadGroupDel = '/api/productdo/del_group'
//图片上传->添加分组
export const postUploadGroupAdd = '/api/productdo/add_group'
// 获取上传凭证
export const getUploadAuth = 'api/upload/get_check_auth'
//获取图片列表
export const postUploadImgList = 'api/product/get_my_img'
//添加图片
export const postUploadImgAdd = '/api/productdo/save_my_img'
//修改图片分组
export const postUploadImgModify = '/api/productdo/set_img_group'
//删除图片
export const postUploadImgDel = '/api/productdo/del_my_img'
//获取收藏列表
export const postCollectionList = '/api/product/get_my_like'
//保存我的模板
export const postMyTemplate = '/api/productdo/save_my_template'
//我的模版-我的设计图片列表
export const postTemplateMyDesignList = '/api/product/get_my_template'
//编辑器->获取画布尺寸
export const postCanvasSizeList =  '/api/product/get_page_sizelist'
//为你推荐列表
export const postRecommendToYouList = '/api/product/get_top_list'
//创建设计获取列表信息
export const postCreateDesignList = '/api/product/get_page_create'
//我的模板 修改模板内容分组
export const postMytTemplateGroup  = '/api/productdo/save_my_template_group'
//我的模板 修改模板内容名称
export const postMyTemplateTitle  = '/api/productdo/save_my_template_title'
//我的模板 删除模板内容
export const postDeleteMyTemplate = '/api/productdo/delete_my_template'
//素材首页获取图片列表
export const postMaterialImgList = '/api/product/get_material_img_list'
//素材首页获取素材列表
export const postMaterialSvgList = '/api/product/get_material_svg_list'
//素材首页获取文字列表
export const postMaterialFontList = '/api/product/get_material_font_list'
//素材中心获取搜索类型列表
export const postMaterialGroup = '/api/product/get_material_group'
//素材中心获取搜索图片列表
export const postSearchMaterialImg = '/api/product/get_search_material_img'
//获取用户信息
export const postUserInfo = '/api/product/get_user_info'
//获取用户订单信息
export const postUserOrderList = '/api/product/get_order_list'
//获取用户发票信息
export const postInvoiceList = '/api/product/get_invoice_list'
//用户申请发票
export const postMyInvoice = '/api/product/add_my_invoice'
//用户发票限制
export const postConfigLimit = '/api/product/get_config_limit'
//热门搜索
export const postHotSearch = '/api/product/get_hot_search'
// 获取会员等级
export const postauth  = '/api/product/get_auth'
// 记录我的下载日志
export const postdown  = '/api/productdo/save_my_down'
// 获取下载次数权限
export const postdownnum  = '/api/product/get_my_down'
// 获取筛选项颜色列表
export const getcolorurl  = '/api/product/get_search_color'
//获取换绑微信的状态
export const postChangeBindingUserInfo = '/api/product/validating_userinfo'
//获取开票记录数据
export const postInvoiceRecords = '/api/product/get_my_invoice_config'
//获取素材中心首页详情
export const postMaterialImgInfo = '/api/product/get_material_img_info'
//获取升级会员/支付数据
export const postPriceList = '/api/product/get_price_list'
//获取seo数据
export const postSeoList = '/api/product/get_seo'
//获取支付二维码
export const postPassageway = '/api/pay/passageway'
//获取支付状态
export const postorderStatus = '/api/pay/get_order_status'
//选择产品权限
export const postCheckGroupAuth = '/api/pay/check_group_auth'
//获取会员到期
export const postMemberPayInfo = '/api/pay/get_pay_info'
// 获取我的模板temurl内容
export const postMyTemplateTemurlinfoUrl = '/api/productdo/get_my_template_info'
//帮助中心获取首页列表
export const postHelpList = '/api/product/get_help_list'
//帮助中心获取搜索列表
export const postSearchHelpList = '/api/product/get_search_help_list'
//帮助中心获取详情列表
export const postHelpInfo = '/api/product/get_help_info'
// 获取字体是否存在
export const posthasfontUrl = '/api/product/get_has_font'
// 获取编辑器模板分组列表
export const posttemplateGroupUrl = '/api/product/get_editor_tem_group'
// 获取手机端模板中心分类
export const postwaptemplateGroupUrl = '/api/product/get_wap_tem_group'
// 获取手机端帮助中心首页二级分类
export const postwaphelpGroupUrl = '/api/product/get_help_group'
// 获取手机端帮助中心首页列表数据
export const postwaphelplistUrl = '/api/product/get_help_list_wap'
// 获取手机端微信登录回调地址
export const postwapwechatUrl = '/api/login/get_wap_wechat_login_url'
// 获取手机端微信登录操作
export const postwapwechatLoginUrl = '/api/login/get_wap_redirect_do'