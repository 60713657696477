<template>
  <!-- 首页加载全屏动画 -->
  <NuxtLoadingIndicator :throttle="0" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
<!--  <quick-entrance v-if="$route.name!=='designs' || $route.name!=='createdesigns'"></quick-entrance>-->
</template>
<script setup>
import QuickEntrance from "./src/components/quickEntrance/quickEntrance";
import {getLocal, setLocal,setLocalLogin} from "@/src/utils/local";
const route = useRoute()
const routeId = ref(true)
const routeList = ref(['designs', 'createdesigns', 'helpCenter', 'helpCenterSearch', 'helpCenterDetails'])
const getRoute = (() => {
  routeId.value = true
  routeList.value.forEach((item) => {
    if (item === route.name) {
      routeId.value = false
    }
  })
})
getRoute()
watch(() => route.name, () => {
  getRoute()
})
// //初始化
onMounted(() => {
  //console.log(getLocal('token'),'token')
});
const nuxtApp = useNuxtApp()

// 是否首次加载
const isFullLoading = ref(true)

nuxtApp.hook('page:start', () => {
  isFullLoading.value = true
})

nuxtApp.hook('page:finish', () => {
  isFullLoading.value = false
})
</script>
<style>

</style>
