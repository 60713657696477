/*
 * @Author: June 1601745371@qq.com
 * @Date: 2024-03-08 11:02:11
 * @LastEditors: June 1601745371@qq.com
 * @LastEditTime: 2024-03-11 11:33:22
 * @FilePath: \github\yft-design\src\plugins\i18n\lang\en.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import el_en from 'element-plus/dist/locale/en.mjs'
export default {
  el: el_en.el,
  message: {
    test: 'test',
    edit: 'Edit',
    template: 'Template',
    material: 'Material',
    text: 'Text',
    image: 'Image',
    tool: 'Tool',
    layer: 'Layer',
    help: 'Help',
    pages: 'Pages',
    addPage: 'Add Page',
    close: 'Close',
    allPages: 'All Pages',
    currentPage: 'Current Page',
    showSafeLine: 'Show Safe Line',
    hideSafeLine: 'Hide Safe Line',
    draggingCanvas: 'Dragging Canvas',
    showBloodsLine: 'Show Bloods Line',
    hideBloodsLine: 'Hide Bloods Line',
    imageResolution: 'Image Resolution',
    imageQuality: 'Image Quality',
    exportFormat: 'Export Format',
    exportRange: 'Export Range',
    selfRange: 'Self Range',
    ignoreBloods: 'Ignore Bloods',

    exportImages: 'Export Images',
    exportPDF: 'Export PDF',
    exportPSD: 'Export PSD',
    exportJSON: 'Export JSON',
    exportSVG: 'Export SVG',
    quantityPerPage: 'Quantity Per Page',
    blankEdges: 'Blank Edges',

    inviteCollaboration: 'Invite Collaboration',
    github: 'Github',
    downloadFiles: 'Download Files',
    uploadFiles: 'Upload Files',
    saveTemplate: 'Save Template',

    undo: 'Undo',
    redo: 'Redo',
    group: 'Group',
    ungroup: 'UnGroup',
    ruler: 'Ruler',
    union: 'Union',
    difference: 'Difference',
    intersection: 'Intersection',
    xor: 'Xor',

    createDesign: 'Create Design',
    referenceLine: 'Reference Line',

    codeStyle: 'Code Style',
    codeContent: 'Code Content',
    codeMargin: 'Code Margin',
    nonMargin: 'Non Margin',
    standardMargin: 'Standard Margin',
    errorRate: 'Error Rate',

    searchTemp: 'Search For Template',
    recommendTemp: 'Recommend',
    myTemp: 'My',
    myPurchases: 'My Purchases',
    myFavorites: 'My Favorites',
    teamTemp: 'Team',

    searchMat: 'Search For Materials',
    recommendMat: 'Recommend',
    myBookmarksMat: 'BookMarks',
    myPurchasesMat: 'Purchases',
    straightLine: 'Straight Line',

    searchText: 'Search For Text',
    addTitle: 'Click To Add Title Text',
    addSubtitle: 'Click To Add Subtitle Text',
    addBodyText: 'Click To Add Body Text',
    hollowOut: 'Hollow',
    direction: 'Direction',
    transverse: 'Transverse',
    recommendText: 'Recommend',
    myBookmarksText: 'BookMarks',
    myPurchasesText: 'Purchases',
    myTemplate: 'My Templates',
    teamTemplate: 'Team Templates',

    uploadImages: 'Upload Images',
    searchImages: 'Search Images',
    recommendImages: 'Recommend',
    myBookmarks: 'BookMarks',
    teamTemplates: 'Templates',
    all: 'All',
    endOfContent: 'End~',

    searchTools: 'Search tools',
    barCode: 'Bar code',
    barCodeTips: 'Quickly generate one-dimensional codes, supporting 4 types of coding',
    QRCode: 'QR code',
    QRCodeTips: 'Quickly generate QR codes, supporting 13 styles',
    AICutoutImage: 'Cut img',
    AICutoutImageTips: 'Utilizing AI technology to achieve intelligent recognition',
    ColorFillImage: 'Image fill color',
    ColorFillImageTips: 'Image color filling, commonly used to change background color',

    searchLayer: 'Search For Layer',
    beginnerGuide: "Beginner's Guide",
    userGuide: 'User Guide',
    shortcuts: 'Shortcuts',
    suggestions: 'Suggestions',
    onlineSupport: 'Online Support',

    model: 'Model',
    dragAndDropFiles: 'Drag And Drop Files Here Or ',
    selectFileUpload: 'Select File Upload',
    imageLimit2MTips: 'For Better Experience, Please Upload Images Within 2MB',
    common: 'Common',
    portrait: 'Portrait',
    rawImage: 'Raw Image',
    resultImage: 'Result Image',
    clear: 'Clear',
    download: 'Download',
    uploading: 'Uploading',
    completeImageCutout: 'Complete Image Cutout',

    files: 'Files',
    title: 'Title',
    subtitle: 'Subtitle',
    horizontalText: 'Horizontal Text',
    verticalText: 'Vertical Text',
    hollowOutText: 'Hollow Out Text',
    circularText: 'Circular Text',
    shape: 'Shape',
    module: 'Module',
  },
  default: {
    textValue: 'Double Click Edit Text',
    cancel: 'Cancel',
    ok: 'OK',
    direction: 'Direction'
  },
  style: {
    canvas: 'Canvas',
    canvasSize: 'Canvas Size',
    style: 'Style',
    width: 'Width',
    height: 'Height',
    w: 'W',
    h: 'H',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    bleedingLine: 'Bleeding Line',
    rightAngle: 'right Angle',
    fillet: 'Fillet',
    unit: 'unit',
    card: 'Card',
    singlePage: 'Single Page',
    custom: 'Custom',
    canvasFill: 'Canvas Fill',
    lockAspectRatio: 'Lock Aspect Ratio',
    unlockAspectRatio: 'Unlock Aspect Ratio',
    applyCanvasToAll: 'Apply Canvas To All',
    solidColorFill: 'Solid Color Fill',
    imageFill: 'Image Fill',
    gradientFill: 'Gradient Fill',
    gridFill: 'Grid Fill',
    shapeFill: 'Shape Fill',
    intelligentFill: 'Intelligent Fill',
    canvasMask: 'Canvas Mask',
    opacity: 'Opacity',
    safetyLine: 'Safety Line',
    minimumSizeLimit: 'The Minimum size Limit Is',
    maximumSizeLimit: 'The Maximum Size Limit Is',

    leftAlign: 'Left Align',
    rightAlign: 'Right Align',
    topAlign: 'Top Align',
    bottomAlign: 'Bottom Align',
    centerHorizontal: 'Center Horizontally',
    verticalCenter: 'Vertical Center',
    top: 'Top',
    bottom: 'Bottom',
    down: 'Down',
    up: 'Up',
    code: 'Code',
    codeValue: 'Code Value',
    bgColor: 'Background',
    codeColor: 'Code Color',
    enableBorder: 'Border',
    codeStyle: 'Code Style',
    codeContent: 'Code Content',
    codeMargin: 'Code Margin',
    noMargin: 'No Margin',
    standardMargin: 'Standard Margin',
    solidBorder: 'Solid Border',
    dashedBorder: 'Dashed Border',
    borderStyle: 'Border Style',
    borderColor: 'Border Color',
    borderThickness: 'Border Thickness',
    enableShadow: 'Shadow',
    horizontalShadow: 'Horizontal Shadow',
    verticalShadow: 'Vertical Shadow',
    fuzzyDistance: 'Fuzzy Distance',
    shadowColor: 'Shadow Color',
    flipVertical: 'Vertical',
    flipHorizontal: 'Horizontal',
    cropImage: 'Crop',
    byShape: 'By Shape',
    byAspectRatio: 'By Aspect Ratio',
    square: '(Square)',
    direction: '(Direction)',
    enableMask: 'Mask',
    maskColor: 'Mask Color',
    randomShape: 'Random Shape',
    replaceimage: 'Replace Image',
    resetStyle: 'Reset Style',
    setAsBg: 'Set As Background',
    gradientAngle: 'Gradient Angle',
    horizontalPosition: 'Horizontal Position',
    verticalPosition: 'Vertical Position',
    strength: 'Strength',
    variance: 'Variance',
    size: 'Size',
    randomGeneration: 'Random Generation',
    colorSelect: 'Color Select',
    customize: 'Customize',
    graphScale: 'Graph Scale',
    lineThickness: 'Line Thickness',
    verticalSpace: 'Vertical Space',
    horizontalSpace: 'Horizontal Space',
    rotationAngle: 'Rotation Angle',
    colorNumber: 'Color Number',

    enableFilters: 'Filter',
    grayscale: 'Grayscale',
    average: 'Average',
    luminosity: 'Luminosity',
    bright: 'Bright',
    mode: 'Mode',
    inversion: 'Inversion',
    sharpening: 'Sharpening',
    concaveConvex: 'Concave-convex',
    matrix: 'matrix',
    sepia: 'Sepia',
    blackAndWhite: 'Black-and-white',
    brownie: 'Brownie',
    vintageColor: 'Vintage Color',
    technicolor: 'Technicolor',
    kodachrome: 'Kodachrome',
    polaroid: 'Polaroid',
    color: 'Color',
    brightness: 'Brightness',
    contrast: 'Contrast',
    saturation: 'Saturation',
    colorful: 'Colorful',
    hue: 'Hue',
    noise: 'Noise',
    pixel: 'Pixel',
    vague: 'Vague',

    axis: 'Axis',
    position: 'Position',

    enableStroke: 'Enable Stroke',
    enableImgStroke: 'Enable Images Stroke',
    onlyShowContours: 'Only Show Contours',
    onlyTransparentLayer: 'Only Supports Transparent Layers',
    strokeWidth: 'Stroke Width',
    cornerStyle: 'Corner Style',
    bevel: 'Bevel',
    round: 'Round',
    miter: 'Miter',
    strokeColor: 'Stroke Color',

    enableShading: 'Enable Shading',
    shadingMode: 'Shading Mode',
    shadingImage: 'Shading Image',
    collage: 'Collage',
    enableGradient: 'Enable Gradient',
  }
};