<template>
  <div class="error-page">
    <div class="error-container">
      <div v-if="error.statusCode === 404" class="error-content">
        <div class="error-number">404</div>
        <h2 class="error-title">页面未找到</h2>
        <p class="error-message">抱歉，您访问的页面似乎已经迷路了</p>
        <button class="error-button" @click="handleError">
          <span class="button-icon">←</span>
          返回首页
        </button>
      </div>
      <div v-else class="error-content">
        <div class="error-number">{{ error.statusCode }}</div>
        <p class="error-message">{{ error.message }}</p>
        <button class="error-button" @click="handleError">
          <span class="button-icon">←</span>
          返回首页
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
// 获取错误信息
const error = useError()

// 处理错误（返回首页）
const handleError = () => {
  clearError({ redirect: '/' })
}
</script>

<style scoped>
.error-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
}

.error-container {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  animation: fadeIn 0.5s ease-out;
}

.error-content {
  text-align: center;
}

.error-number {
  font-size: 120px;
  font-weight: bold;
  background: linear-gradient(45deg, #FF6B6B, #da8743);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  line-height: 1;
  animation: pulse 2s infinite;
}

.error-title {
  font-size: 28px;
  color: #2d3436;
  margin-bottom: 15px;
  font-weight: 600;
}

.error-message {
  font-size: 16px;
  color: #636e72;
  margin-bottom: 30px;
  line-height: 1.6;
}

.error-button {
  background: linear-gradient(45deg, #FF6B6B, #da8743);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.error-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.button-icon {
  font-size: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* 响应式设计 */
@media (max-width: 480px) {
  .error-number {
    font-size: 80px;
  }

  .error-title {
    font-size: 24px;
  }

  .error-message {
    font-size: 14px;
  }

  .error-button {
    padding: 10px 25px;
    font-size: 14px;
  }
}
</style>