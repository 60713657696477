export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"name":"description","content":"Your description here"}],"link":[{"rel":"stylesheet","href":"//at.alicdn.com/t/c/font_4485398_j3b3zxn3knk.css"}],"style":[],"script":[{"innerHTML":"\n            (function() {\n              var isMobile = window.innerWidth <= 768 || /mobile|android|iphone/i.test(navigator.userAgent);\n              document.documentElement.setAttribute('data-device', isMobile ? 'mobile' : 'desktop');\n            })();\n          ","type":"text/javascript"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false