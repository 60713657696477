import { Object as FabricObject, Rect as fabricRect, classRegistry, ImageSource } from "fabric"
import { BarcodeProps } from "@/src/types/canvas"

export class Rect extends fabricRect {
    constructor(element: ImageSource, options?: FabricObject<BarcodeProps>) {
        super(element, { filters: [], ...options })
    }
    _render(ctx: CanvasRenderingContext2D) {
        const roundValue = this.roundValue || 0;
        this.rx = (1 / this.scaleX) * roundValue;
        this.ry = (1 / this.scaleY) * roundValue;
        super._render(ctx);
    }
}

classRegistry.setClass(Rect, 'Rect')