import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { Canvas, Object as FabricObject, Textbox, Group, Point, IText, Line,Image as fabImage,Pattern,util,Rect } from 'fabric'
import { isMobile } from '@/src/utils/common'
import { Templates } from '@/src/mocks/templates'
import { WorkSpaceThumbType, WorkSpaceDrawType, propertiesToInclude } from "@/src/configs/canvas";
import { useFabricStore } from '@/src/store/modules/fabric'
import {useElementBounding} from '@vueuse/core'
import {FabricTool} from '@/src/app/fabricTool'
import {FabricGuide} from '@/src/app/fabricGuide'
import {HoverBorders} from '@/src/app/hoverBorders'
import {WheelScroll} from '@/src/app/wheelScroll'
import {FabricRuler} from '@/src/app/fabricRuler'

import {FabricCanvas} from '@/src/app/fabricCanvas'
import {Keybinding} from '@/src/app/keybinding'
import {defaultControls, textboxControls,verticalTextControlsconfig} from '@/src/app/fabricControls'
import {useTemplatesStore,useSnapshotStore} from '@/src/store'
import useCommon from './useCommon'
import useHammer from './useHammer'
import { ArcText } from '@/src/extension/object/ArcText'
import { getFontList,postauthdo } from '@/src/api/apiconfig/main'
import { getObjectsBoundingBox  } from '@/src/utils/common'
import { cuscheckImages } from '@/src/hooks/useUpload'
// 添加水印
import gdwater from "@/public/img/water.svg"
import { getImageSize } from "@/src/utils/image";
import { nanoid } from "nanoid";
// 请求模板数据
import { postTemplateTemurlinfo,postMyTemplateTemurlinfo } from '@/src/api/apiconfig/main'
import { getLocal } from "~/src/utils/local";
let canvas: null | FabricCanvas = null

// 初始化配置
const initConf = () => {
  FabricObject.ownDefaults.objectCaching = false
  FabricObject.ownDefaults.borderColor = '#51B9F9'
  FabricObject.ownDefaults.cornerColor = 'white'
  FabricObject.ownDefaults.cornerStrokeColor = '#c0c0c0'
  FabricObject.ownDefaults.borderOpacityWhenMoving = 1
  FabricObject.ownDefaults.borderScaleFactor = 1.5
  FabricObject.ownDefaults.cornerSize = 10
  FabricObject.ownDefaults.cornerStyle = 'rect'
  FabricObject.ownDefaults.centeredScaling = false
  FabricObject.ownDefaults.centeredRotation = true
  FabricObject.ownDefaults.transparentCorners = false
  FabricObject.ownDefaults.rotatingPointOffset = 1
  FabricObject.ownDefaults.lockUniScaling = true
  FabricObject.ownDefaults.hasRotatingPoint = false
  FabricObject.ownDefaults.controls = defaultControls()

  Object.assign(Textbox.ownDefaults, { controls: textboxControls() })
  Object.assign(IText.ownDefaults, { controls: verticalTextControlsconfig() })
  const mixin = {
    getWidthHeight(noFixed = false): Point {
      const objScale = (this as FabricObject).getObjectScaling()
      const point = (this as FabricObject)._getTransformedDimensions({
        scaleX: objScale.x,
        scaleY: objScale.y,
      })
      if (!noFixed) {
        point.setX(point.x)
        point.setY(point.y)
      }
      return point
    },
    getHeight() {
      return this.getWidthHeight().y
    },
    getWidth() {
      return this.getWidthHeight().x
    },
  }

  Object.assign(FabricObject.prototype, mixin)
}

// 更新视图区长宽[弃用]
const setCanvasTransforms = () => {
  if(process.client){
    if (!canvas) return
    const fabricStore = useFabricStore()
    const { zoom, wrapperRef, scalePercentage } = storeToRefs(fabricStore)
    const { width, height } = useElementBounding(wrapperRef.value)

    canvas.setDimensions({width: width.value, height: height.value})
    const objects = canvas.getObjects().filter(ele => !WorkSpaceThumbType.includes(ele.id))
    const boundingBox = Group.prototype.getObjectsBoundingBox(objects)
    if (!boundingBox) return
    let boxWidth = boundingBox.width, boxHeight = boundingBox.height
    let centerX = boundingBox.centerX, centerY = boundingBox.centerY
    const workSpaceDraw = canvas.getObjects().filter(item => item.id === WorkSpaceDrawType)[0]
    if (workSpaceDraw) {
      boxWidth = workSpaceDraw.width
      boxHeight = workSpaceDraw.height
      centerX = workSpaceDraw.left + workSpaceDraw.width / 2
      centerY = workSpaceDraw.top + workSpaceDraw.height / 2
    }
    zoom.value = Math.min(canvas.getWidth() / boxWidth, canvas.getHeight() / boxHeight) * scalePercentage.value / 100
    canvas.setZoom(zoom.value)
    canvas.absolutePan(new Point(centerX, centerY).scalarMultiply(zoom.value).subtract(canvas.getCenterPoint()))
  }
}
// 更新视图区长宽
const setCanvasTransform = async () => {
  if (!canvas) return
  const fabricStore = useFabricStore()
  const { zoom, wrapperRef, scalePercentage } = storeToRefs(fabricStore)
  const { width, height } = useElementBounding(wrapperRef.value)
  canvas.setDimensions({width: width.value, height: height.value})
  const objects = canvas.getObjects().filter(ele => !WorkSpaceThumbType.includes(ele.id))
  //const boundingBox = Group.prototype.getObjectsBoundingBox(objects)
  const boundingBox = getObjectsBoundingBox(objects)
  if (!boundingBox) return
  let boxWidth = boundingBox.width, boxHeight = boundingBox.height
  let centerX = boundingBox.centerX, centerY = boundingBox.centerY
  const workSpaceDraw = canvas.getObjects().filter(item => item.id === WorkSpaceDrawType)[0]
  if (workSpaceDraw) {
    boxWidth = workSpaceDraw.width
    boxHeight = workSpaceDraw.height
    centerX = workSpaceDraw.left + workSpaceDraw.width / 2
    centerY = workSpaceDraw.top + workSpaceDraw.height / 2
  }
  zoom.value = Math.min(canvas.getWidth() / boxWidth, canvas.getHeight() / boxHeight) * scalePercentage.value / 100
  canvas.setZoom(zoom.value)
  canvas.absolutePan(new Point(centerX, centerY).scalarMultiply(zoom.value).subtract(canvas.getCenterPoint()))
}


const initCanvas = () => {
  if(process.client) {
    const fabricStore = useFabricStore()
    const {canvasRef} = storeToRefs(fabricStore)
    const fabricWidth = fabricStore.getWidth()
    const fabricHeight = fabricStore.getHeight()
    if (!canvasRef.value) return
    canvas = new FabricCanvas(canvasRef.value, {
      width: fabricWidth,
      height: fabricHeight
    })
    // const keybinding = new Keybinding()
    new FabricTool(canvas)
    new FabricGuide(canvas)
    new HoverBorders(canvas)
    new WheelScroll(canvas)
    if(!isMobile()){
      new FabricRuler(canvas)
    }
    canvas.preserveObjectStacking = true
    canvas.renderAll()
  }
}

const initEvent = () => {
  if(process.client) {
    if (!canvas) return
    const templatesStore = useTemplatesStore()
    canvas.on('object:modified', () => templatesStore.modifedElement())
  }
}

// 初始化模板
const initTemplate = async (temid,isuser,mycreate) => {
  if(process.client){
    if (!canvas) return
    const templatesStore = useTemplatesStore()
    const { initCommon } = useCommon()
    const { initHammer } = useHammer()
    const { currentTemplate } = storeToRefs(templatesStore)

    if(isuser != 0){
      let newarr = isuser.split('_');
      let newwidth = parseInt(newarr[0]);
      let newheight = parseInt(newarr[1]);

      currentTemplate.value.workSpace.fill = '#fff';
      currentTemplate.value.workSpace.color = '#fff';
      let arr = currentTemplate.value.objects.filter(item => item.id == 'WorkSpaceDrawType');

      currentTemplate.value.objects = arr;
      currentTemplate.value.objects.forEach((item,index)=>{
        if(item.id=='WorkSpaceDrawType'){
            item.fill = '#fff';
            item.opacity = 0;
        }
      })
      if (isNaN(newwidth) || isNaN(newheight)) {
        currentTemplate.value.width = 500;
        currentTemplate.value.height = 500;
        currentTemplate.value.objects[0].width = 500;
        currentTemplate.value.objects[0].height= 500;
      }else{
        currentTemplate.value.width = parseInt(newarr[0]);
        currentTemplate.value.height = parseInt(newarr[1]);
        currentTemplate.value.objects[0].width = parseInt(newarr[0]);
        currentTemplate.value.objects[0].height= parseInt(newarr[1]);
      }

      await canvas.loadFromJSON(currentTemplate.value)

    }else{
      let templateData;
      let tempalteContent;
      if(mycreate == 0){
        templateData =  await postTemplateTemurlinfo({id:temid})
        tempalteContent = JSON.parse(templateData.tempUrl);

        tempalteContent.objects.forEach((item,index) => {

          if(item.type =="Textbox"){
            //item.editable =false
          }
        })

      }else{
        templateData =  await postMyTemplateTemurlinfo({id:temid})
        tempalteContent = JSON.parse(templateData.tempUrl);
        cuscheckImages(tempalteContent.objects).then((validObjects) => {
          tempalteContent.objects = validObjects;
        });
      }
      templatesStore.setTemplates([tempalteContent],templateData.title);
      await canvas.loadFromJSON(tempalteContent)
    }

    setCanvasTransform()
    initCommon()
    // initHammer()
    initEvent()
    addwater();
  }
}

export const initEditor = async (temid,isuser,mycreate) => {
  if(process.client) {
    const fabricStore = useFabricStore()
    const {wrapperRef} = storeToRefs(fabricStore)
    initConf()
    initCanvas()
    await initTemplate(temid,isuser,mycreate)
    const snapshotStore = useSnapshotStore()
    await snapshotStore.initSnapshotDatabase()
    const {width, height} = useElementBounding(wrapperRef.value)
    watch([width, height], () => {
      setCanvasTransform()
    })
  }
}

export const  getOnlineFonts = async () =>{
  const res = await getFontList({})

    const style = document.createElement("style");
    style.type = "text/css";
    res.zh.forEach(fontInfo => {
      style.appendChild(document.createTextNode(
        `@font-face {font-family: '${fontInfo.value}'; src: url(${fontInfo.tempUrl}) format('truetype');}`
      ));
    })

    res.en.forEach(fontInfo => {
      style.appendChild(document.createTextNode(
        `@font-face {font-family: '${fontInfo.value}'; src: url(${fontInfo.tempUrl}) format('truetype');}`
      ));
    })

    res.qt.forEach(fontInfo => {
      style.appendChild(document.createTextNode(
          `@font-face {font-family: '${fontInfo.value}'; src: url(${fontInfo.tempUrl}) format('truetype');}`
      ));
    })

    document.head.appendChild(style);

}


export const addwater = async (isshow = true) => {
  if (!canvas) return;
  let token = getLocal('token');
  if (token) {
    let nowgroup = await postauthdo({});
    if (nowgroup.data > 1 ) {
      canvas.overlayImage = null;
      canvas.renderAll();
      return;
    }
  }

  canvas.overlayImage = null;
  const workspace = canvas.getObjects().find((item: any) => item.id === WorkSpaceDrawType);

  let waterCanvas = document.createElement('canvas');
  waterCanvas.width = workspace.width;
  waterCanvas.height = workspace.height;
  waterCanvas.style.position = 'fixed';
  waterCanvas.style.opacity = '0';
  waterCanvas.style.zIndex = '-1';
  let ctx = waterCanvas.getContext('2d')!;

  // 创建一个新的Image对象
  const img = new Image();
  //img.src = 'http://cdn.dancf.com/fe-assets/20220427/0febd2a022ea179d9f761eb04cdc2b00.svg';
  //img.src = '/img/water.svg';
  img.src = gdwater
  img.crossOrigin = "anonymous";

  img.onload = async function() {
    // 创建一个缩放的图案
    const patternCanvas = document.createElement('canvas');
    const patternCtx = patternCanvas.getContext('2d')!;

    // 设置缩放比例
    const scale = 4.5; // 调整此值以控制图案的密度
    patternCanvas.width = img.width * scale;
    patternCanvas.height = img.height * scale;

    // 绘制缩放后的图像
    patternCtx.drawImage(img, 0, 0, patternCanvas.width, patternCanvas.height);

    // 创建图案
    const pattern = ctx.createPattern(patternCanvas, 'repeat');
    ctx.fillStyle = pattern;

    // 填充画布
    ctx.fillRect(0, 0, workspace.width, workspace.height);

    const dataURL = waterCanvas.toDataURL();
    waterCanvas = null;
    ctx = null;

    canvas.overlayImage = await fabImage.fromURL(dataURL, {}, {
      id: nanoid(10),
      crossOrigin: 'anonymous',
      left: 0,
      top: 0,
      angle: 0,
      opacity: 1
    }); // 清空覆盖层
    canvas.renderAll();
  };
}

export default (): [FabricCanvas] => [canvas as FabricCanvas]