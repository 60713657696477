import Cookies from 'js-cookie';
/**
 * get localStorage 获取本地存储
 * @param { String } key
 */
export function getLocal(key: string) {

    if (!key) throw new Error('key is empty');
    //const value = localStorage.getItem(key);
    const value = Cookies.get(key);
    return value ? JSON.parse(value) : null;
}

/**
 * set localStorage 设置本地存储
 * @param { String } key
 * @param value
 */
export function setLocal(key: string, value: unknown) {
    if (!key) throw new Error('key is empty');
    if (!value) return;
    //return localStorage.setItem(key, JSON.stringify(value));
    return Cookies.set(key, JSON.stringify(value), { expires: 1 });

}
export function setLocalLogin(key: string, value: unknown) {
    if (!key) throw new Error('key is empty');
    if (!value) return;
    //return localStorage.setItem(key, JSON.stringify(value));
    return Cookies.set(key, JSON.stringify(value), { expires: 1 });

}
/**
 * set localStorage 设置本地存储一年时间
 * @param key
 * @param value
 */
export function setEternalLocal(key: string, value: unknown){
    if (!key) throw new Error('key is empty');
    //if (!value) return;
    return Cookies.set(key, JSON.stringify(value), { expires: 365 });
}

/**
 * remove localStorage 移除某个本地存储
 * @param { String } key
 */
export function removeLocal(key: string) {
    if (!key) throw new Error('key is empty');
    //return localStorage.removeItem(key);
    Cookies.remove(key);
}

/**
 * clear localStorage 清除本地存储
 */
export function clearLocal() {
    //return localStorage.clear();
    const cookies = Cookies.get();
    for (let key in cookies) {
        Cookies.remove(key);
    }
}
