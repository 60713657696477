import { App } from 'vue'

import FileInput from '@/src/components/FileInput.vue'
import ColorPicker from '@/src/components/ColorPicker/index.vue'
import ColorButton from '@/src/components/ColorButton.vue'
import TextColorButton from '@/src/components/TextColorButton.vue'
import FullscreenSpin from '@/src/components/FullscreenSpin.vue'
import LinePointMarker from '@/src/components/LinePointMarker.vue'
import SwipeInput from '@/src/components/SwipeInput.vue'
import FileUpload from '@/src/components/FileUpload/index.vue'
import ImageMatting from '@/src/components/ImageMatting/index.vue'
import ImageFillColor from '@/src/components/ImageFillColor.vue'

const components = {
  FileInput,
  ColorButton,
  TextColorButton,
  ColorPicker,
  FullscreenSpin,
  LinePointMarker,
  SwipeInput,
  FileUpload,
  ImageMatting,
  ImageFillColor,
}

export default {
  install(app: App) {
    for (const key of Object.keys(components)) {
      app.component(key, components[key as keyof typeof components])
    }
  }
}
