import routerOptions0 from "D:/phpstudy_pro/wwwroot/gitee_sskt_customer/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "D:/phpstudy_pro/wwwroot/gitee_sskt_customer/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}